<template>
  <div>
    <LayoutCheckoutHeader />
      <!--<BasicBtErrorBoundary>-->
        <main class="pt-4 lg:pt-32">
          <slot />
        </main>
      <!--</BasicBtErrorBoundary>-->
    <LayoutCheckoutFooter :isReservation="$route?.path?.includes('reservation')" />
  </div>
</template>
<script setup lang="ts">
</script>